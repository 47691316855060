import * as React from 'react';

import Layout from '../components/layout';

const ContactPage = () => (
  <Layout>
    <div className="px-8 py-4 flex-1">
      <h1 className="text-theme-red ">Starter4Ten Contact</h1>
      <p>Coming Soon</p>
    </div>
  </Layout>
);

export default ContactPage;
